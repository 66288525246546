/* App.css */

:root {
  /* Modern color palette */
  --primary-color: #333333;
  --accent-color: #666666;
  --text-color: #222222;
  --background-color: #ffffff;
  --light-gray: #f2f2f2;
  --medium-gray: #cccccc;
  --dark-gray: #111111;
}

/* Global styles */
body {
  font-family: "Open Sans", sans-serif;
  line-height: 1.6;
  color: var(--text-color);
  background-color: var(--background-color);
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Minimalistic navbar styles */
.navbar {
  background-color: var(--background-color);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  border-bottom: 1px solid var(--light-gray);
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.navbar-brand {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--primary-color);
  text-decoration: none;
  transition: color 0.3s ease;
}

.navbar-brand:hover {
  color: var(--accent-color);
}

.navbar-nav {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  flex-direction: row;
}

.nav-item {
  margin-left: 20px;
}

.nav-link {
  color: var(--text-color);
  text-decoration: none;
  font-weight: 500;
  font-size: 1rem;
  padding: 10px 15px;
  transition: color 0.3s ease, background-color 0.3s ease;
  border-radius: 4px;
}

.nav-link:hover {
  color: var(--accent-color);
  background-color: var(--light-gray);
}

.nav-link.active {
  color: var(--accent-color);
  background-color: var(--light-gray);
}

/* Main content */
main {
  flex-grow: 1;
  padding-top: 80px;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Footer */
.footer {
  background-color: var(--light-gray);
  color: var(--text-color);
  padding: 20px 0;
  text-align: center;
  margin-top: 40px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .navbar .container {
    flex-direction: column;
    align-items: center;
    padding: 10px 20px;
  }

  .navbar-nav {
    margin-top: 15px;
    justify-content: center;
    width: 100%;
  }

  .nav-item {
    margin: 0 5px;
  }

  .nav-link {
    padding: 8px 12px;
  }
}

/* Additional global styles */
.btn-primary,
.cta-button {
  background-color: var(--accent-color);
  border: none;
  color: #ffffff;
  padding: 12px 30px;
  border-radius: 30px;
  font-size: 1rem;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.3s ease;
  text-decoration: none;
}

.btn-primary:hover,
.cta-button:hover {
  background-color: #43a047; /* Slightly darker green */
  transform: translateY(-3px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.section-title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 2rem;
  color: var(--primary-color);
  text-align: center;
}

.card {
  border: none;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  background-color: var(--background-color);
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif;
  color: var(--primary-color);
}

p {
  font-size: 1rem;
  color: var(--text-color);
}

/* Headings */
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--primary-color);
}

/* Links */
a {
  color: var(--accent-color);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
