/* Home.css */

:root {
  --primary-color: #4A90E2;
  --secondary-color: #34495e;
  --accent-color: #a4d4ff;
  --background-color: #f2f8ff;
  --text-color: #222222;
  --light-gray: #f2f2f2;
  --medium-gray: #cccccc;
  --dark-gray: #111111;
}

body {
  background-color: var(--background-color);
}

.home {
  background-color: var(--background-color);
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.hero {
  text-align: center;
  padding: 150px 0;
  margin-top: 0;
  background: linear-gradient(
    135deg,
    var(--accent-color) 0%,
    var(--primary-color) 100%
  );
  color: #ffffff;
}

.hero h1 {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 20px;
}

.hero p {
  font-size: 1.5rem;
  margin-top: 10px;
}

.home-content {
  padding-top: 40px;
}

.stats-section,
.services,
.projects,
.cta {
  padding: 60px 0;
}

.section-title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 3rem;
  color: var(--primary-color);
  text-align: center;
}

.stats-row {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.stat-item {
  flex: 1;
  min-width: 200px;
  margin: 10px;
  text-align: center;
}

.stat-box {
  background-color: var(--background-color);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.stat-box h2 {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 10px;
}

.stat-box p {
  font-size: 1.1rem;
  color: var(--text-color);
}

.service-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
}

.service-item {
  background-color: #ffffff;
  border: 1px solid var(--light-gray);
  border-radius: 8px;
  padding: 30px;
  width: 300px;
  text-align: center;
  transition: box-shadow 0.3s ease;
}

.service-item:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.service-icon-wrapper {
  background-color: #ffffff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 20px auto;
}

.service-icon {
  font-size: 1.5rem;
  color: var(--accent-color);
}

.service-item h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.service-item p {
  font-size: 1rem;
}

.project-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 40px;
}

.project-item {
  background-color: var(--light-gray);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
}

.project-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.project-icon {
  background-color: var(--primary-color);
  color: var(--background-color);
  font-size: 2.5rem;
  padding: 20px;
  text-align: center;
}

.project-content {
  padding: 20px;
}

.project-item h3 {
  font-size: 1.5rem;
  color: var(--primary-color);
  margin-bottom: 10px;
}

.project-item p {
  font-size: 1rem;
  margin-bottom: 15px;
}

.project-item .client {
  font-size: 0.9rem;
  color: var(--accent-color);
  font-weight: bold;
}

.cta {
  text-align: center;
}

.cta-button {
  margin-top: 30px;
  background-color: var(--accent-color);
  color: #ffffff;
  padding: 12px 24px;
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #0056b3; /* Slightly darker blue */
}

h2 {
  text-align: center;
  margin-bottom: 40px;
  color: var(--primary-color);
}

/* Animation keyframes */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  animation: fadeIn 0.8s ease-out forwards;
}

@media (prefers-reduced-motion: reduce) {
  .fade-in {
    animation: none;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-up {
  animation: fadeInUp 0.6s ease-out forwards;
  opacity: 0;
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .service-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .hero h1 {
    font-size: 2.5rem;
  }

  .hero p {
    font-size: 1rem;
  }

  .service-grid {
    flex-direction: column;
    align-items: center;
  }

  .stats-row {
    flex-direction: column;
  }

  .stat-item {
    margin: 10px 0;
  }

  .project-grid {
    grid-template-columns: 1fr;
  }
}

/* Service Icons */
.service-icon-wrapper {
  background-color: var(--accent-color);
  border-radius: 50%;
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
}

.service-icon {
  font-size: 1.2rem;
  color: #ffffff;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.service-icon svg {
  width: 15%;
  height: 15%;
}

/* Service Item Titles */
.service-item h3 {
  font-size: 1.5rem;
  color: var(--primary-color);
  margin-top: 0;
  margin-bottom: 15px;
  text-align: center;
}

/* Adjust Service Item */
.service-item {
  padding: 20px;
}

/* Responsive adjustments if needed */
@media (max-width: 960px) {
  /* Add any mobile-specific styles */
}

/* Swiper custom styles */
.services-swiper {
  width: 100%;
  height: 100%;
}

.services-swiper .swiper-pagination-bullet {
  background: #fff;
  opacity: 0.5;
}

.services-swiper .swiper-pagination-bullet-active {
  opacity: 1;
}

.services-swiper .swiper-button-prev,
.services-swiper .swiper-button-next {
  color: #fff;
}

.services-swiper .swiper-button-prev:after,
.services-swiper .swiper-button-next:after {
  font-size: 24px;
}

.cursor {
  animation: blink 1s step-end infinite;
}

@keyframes blink {
  from, to { opacity: 1; }
  50% { opacity: 0; }
}
